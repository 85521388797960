import "./styles.css";

import { useEffect } from "react";
import React from "react";

const HomePageSearch = () => {
  useEffect(() => {}, []);

  return (
    <div className="search-home">
      <div className="deals-home__wrapper">
        <h2 className="deals-home__heading">Узнать цену на игру/DLC/валюту</h2>

        <p className="deals-home__paragraph">Поиск игры и DLC для Xbox</p>
        <span className="deals-home__button">Поиск игр</span>
      </div>
      <div></div>
    </div>
  );
};

export default HomePageSearch;

<div className="deals-home"></div>;
