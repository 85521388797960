import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../img/logo.png";

import {
  getDealsGames,
  getNewGames,
  getTopPaidGames,
} from "../../../features/DealsSlice/DealsSlice";
import { getCarrencyCurSu } from "../../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";
import DealsItem from "../DealsItemListDreamTeam";
import { BarLoader } from "react-spinners";
const DealsHomeListDreamTeam = () => {
  const dispatch = useDispatch();

  const dealsGamesListId = useSelector((state) => state.deals.dealsGamesListId);
  const isFetchingDeals = useSelector((state) => state.deals.isFetchingDeals);

  useEffect(() => {
    dispatch(getCarrencyCurSu());
    dispatch(getDealsGames());
  }, []);

  return (
    <div className="deals-list">
      <div

      // onClick={() => {
      //   dispatch(getDealsGames());
      // }}
      >
        Распродажа
      </div>

      <div className="deals-list">
        {dealsGamesListId.map((item) => (
          <DealsItem key={item} id={item} />
        ))}
      </div>
    </div>
  );
};

export default DealsHomeListDreamTeam;
