import "./styles.css";

import { useEffect } from "react";
import React from "react";

const HomePageDeals = () => {
  useEffect(() => {}, []);

  return (
    <div className="deals-home">
      <div className="deals-home__wrapper">
        <h2 className="deals-home__heading">Скидки на цифровые игры</h2>
        <p className="deals-home__paragraph">
          Наслаждайтесь большими скидками на игры и DLC для Xbox.
        </p>
        <span className="deals-home__button">
          Посмотреть все игры по скидке
        </span>
      </div>
    </div>
  );
};

export default HomePageDeals;
