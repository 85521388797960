import React from "react";

import "./styles.css";
import { useState, useEffect } from "react";

function HomePageInstall() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    console.log("in use"); // выводит в консоль
    const handler = (e) => {
      console.log("in handler"); // не выводит в консоль
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);
  const onClick = (e) => {
    // alert("Click");
    e.preventDefault();
    if (promptInstall) {
      promptInstall.prompt();
    } else {
      return;
    }
  };

  return (
    supportsPWA && (
      <button onClick={(e) => onClick(e)}>Добавить на главный экран</button>
    )
  );
}

export default HomePageInstall;
