import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getSearchGamesInfo,
//   getSearchGamesList,
//   getSearchGamesInfoPriceTr,
//   setSearchGamesList,
// } from "../../features/SearchSlice/SearchSlice.js";

const GamePage = () => {
  const [priceAr, setPriceAr] = useState(0);
  const [priceTr, setPriceTr] = useState(0);

  const dispatch = useDispatch();
  const searchGameImg = useSelector((state) => state.search.searchGameImg);
  const searchGameName = useSelector((state) => state.search.searchGameName);
  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );
  // const carrencyAr = useSelector((state) => state.carrency.carrencyAr); // Курс арг в рублю
  // const carrencyTr = useSelector((state) => state.carrency.carrencyTr); // Курс тур в рублю

  const carrencyAr = 2.24; // Курс арг в рублю
  const carrencyTr = 0.31; // Курс тур в рублю

  const calcPriceRub = () => {
    const x = searchGamePriceTr / carrencyTr;
    const y = searchGamePriceAr / carrencyAr;

    setPriceTr(x);
    setPriceAr(y);

    // setPriceTr(searchGamePriceTr / carrencyTr);
    // setPriceAr(searchGamePriceAr / carrencyAr);
  };

  useEffect(() => {
    calcPriceRub();

    // setTimeout(() => (setPriceAr(searchGamePriceAr / carrencyAr), 3000));
    // setTimeout(() => (setPriceTr(searchGamePriceTr / carrencyTr), 2000));
    // console.log(priceAr);
    // console.log(searchGamePriceTr);
    // console.log(priceTr + "цена в турции");
  }, [searchGameName, searchGameImg]);

  return (
    <div>
      <div style={{ fontSize: 25 }}>{searchGameName}</div>
      <img style={{ width: 350 }} src={searchGameImg} />
    </div>
  );
};

export default GamePage;
