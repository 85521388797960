import "./styles.css";
import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getSearchGamesInfo,
//   getSearchGamesList,
//   getSearchGamesInfoPriceTr,
//   setSearchGamesList,
// } from "../../features/SearchSlice/SearchSlice.js";

const GamePageUser = () => {
  const [priceAr, setPriceAr] = useState(0);
  const [priceTr, setPriceTr] = useState(0);

  const dispatch = useDispatch();
  const searchGameImg = useSelector((state) => state.search.searchGameImg);
  const searchGameName = useSelector((state) => state.search.searchGameName);
  const searchGameId = useSelector((state) => state.search.searchGameId);
  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );

  const searchGameDescription = useSelector(
    (state) => state.search.searchGameDescription
  );

  useEffect(() => {}, []);

  return (
    <div className="game-info__description">
      <div className="game-info__img">
        <img src={searchGameImg} />
      </div>
      <div className="game-info__specification">
        <a
          href={`https://www.microsoft.com/ru-ru/p/game/${searchGameId}`}
          target="_blank"
        >
          {searchGameName}
        </a>
        <div className="game-info__short-description">
          {searchGameDescription}
        </div>
      </div>
    </div>
  );
};

export default GamePageUser;
