import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../img/logo.png";
import { ClipLoader } from "react-spinners";

const CarrencyUserMaster = () => {
  const dispatch = useDispatch();

  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );

  const carrencyTrCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyTrCurSu
  );
  // const carrencyEgCurSu = useSelector(
  //   (state) => state.carrencyCurSu.carrencyEgCurSu
  // );
  // const carrencyNgCurSu = useSelector(
  //   (state) => state.carrencyCurSu.carrencyNgCurSu
  // );
  const carrencyRuCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyRuCurSu
  );

  //Обычные цены
  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );

  const searchGamePriceUsa = useSelector(
    (state) => state.search.searchGamePriceUsa
  );
  const searchGamePriceEg = useSelector(
    (state) => state.search.searchGamePriceEg
  );
  const searchGamePriceNg = useSelector(
    (state) => state.search.searchGamePriceNg
  );

  // Цены со скидками
  const searchGamePriceArSale = useSelector(
    (state) => state.search.searchGamePriceArSale
  );
  const searchGamePriceTrSale = useSelector(
    (state) => state.search.searchGamePriceTrSale
  );

  const searchGamePriceUsaSale = useSelector(
    (state) => state.search.searchGamePriceUsaSale
  );
  // const searchGamePriceEgSale = useSelector(
  //   (state) => state.search.searchGamePriceEgSale
  // );

  // const searchGamePriceNgSale = useSelector(
  //   (state) => state.search.searchGamePriceNgSale
  // );
  // =====
  const searchProductType = useSelector(
    (state) => state.search.searchProductType
  );

  const whatXbox = useSelector((state) => state.search.whatXbox);

  const [markup, setMurkUp] = useState(300); // цена в рублях в аргентине

  const [priceAr, setPriceAr] = useState(0); // цена в рублях в аргентине
  const [priceTr, setPriceTr] = useState(0); // цена в рублях в турции
  const [priceUsa, setPriceUsa] = useState(0); // цена в рублях в США
  // const [priceEg, setPriceEg] = useState(0); // цена в рублях в египет
  // const [priceNg, setPriceNg] = useState(0); // цена в рублях в nigeria

  const [priceArSale, setPriceArSale] = useState(0); // цена в рублях в аргентине на скидке
  const [priceTrSale, setPriceTrSale] = useState(0); // цена в рублях в турции на скидке
  const [priceUsaSale, setPriceUsaSale] = useState(0); // цена в рублях в США на скидке
  // const [priceEgSale, setPriceEgSale] = useState(0); // цена в рублях в египте на скидке
  // const [priceNgSale, setPriceNgSale] = useState(0); // цена в рублях в nigeria на скидке

  const [priceArDt, setPriceArDt] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam
  const [priceTrDt, setPriceTrDt] = useState(0); // цена в рублях в турции c накруткой DreamTeam
  const [priceUsaDt, setPriceUsaDt] = useState(0); // цена в рублях в США c накруткой DreamTeam
  // const [priceEgDt, setPriceEgDt] = useState(0); // цена в рублях в egypet c накруткой DreamTeam
  // const [priceNgDt, setPriceNgDt] = useState(0); // цена в рублях в nigeria c накруткой DreamTeam

  const [priceArDtSale, setPriceArDtSale] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam на скидке
  const [priceTrDtSale, setPriceTrDtSale] = useState(0); // цена в рублях в турции c накруткой DreamTeam на скидке
  const [priceUsaDtSale, setPriceUsaDtSale] = useState(0); // цена в рублях в США c накруткой DreamTeam на скидке
  // const [priceEgDtSale, setPriceEgDtSale] = useState(0); // цена в рублях в egypet c накруткой DreamTeam на скидке
  // const [priceNgDtSale, setPriceNgDtSale] = useState(0); // цена в рублях в egypet c накруткой DreamTeam на скидке

  const [isSwitchCalc, setIsSwitchCalc] = useState(false);

  const priceCalculationConv = () => {
    // console.log("чык-чырык");

    // console.log(carrencyNgCurSu);
    // console.log(searchGamePriceNg);

    setPriceAr(searchGamePriceAr / (carrencyArCurSu / carrencyRuCurSu)); // цена в рублях в аргентине
    setPriceTr(searchGamePriceTr / (carrencyTrCurSu / carrencyRuCurSu)); // цена в рублях в турции
    setPriceUsa(searchGamePriceUsa * carrencyRuCurSu); // цена в рублях в США
    // setPriceEg(searchGamePriceEg / (carrencyEgCurSu / carrencyRuCurSu)); // цена в рублях в egypet
    // setPriceNg(searchGamePriceNg / (carrencyNgCurSu / carrencyRuCurSu)); // цена в рублях в nigeria

    setPriceArSale(searchGamePriceArSale / (carrencyArCurSu / carrencyRuCurSu)); // цена в рублях в аргентине на скидке
    setPriceTrSale(searchGamePriceTrSale / (carrencyTrCurSu / carrencyRuCurSu)); // цена в рублях в турции на скидке
    setPriceUsaSale(searchGamePriceUsaSale * carrencyRuCurSu); // цена в рублях в США на скидке

    // setPriceEgSale(searchGamePriceEgSale / (carrencyEgCurSu / carrencyRuCurSu)); // цена в рублях в egypet на скидке
    // setPriceNgSale(searchGamePriceNgSale / (carrencyNgCurSu / carrencyRuCurSu)); // цена в рублях в nigeria на скидке
  };

  const priceCalculationDtDonate = () => {
    // if (searchProductType === "Consumable" || whatXbox === true) {
    if (searchProductType === "Consumable") {
      setMurkUp(400);
    } else setMurkUp(300);

    if (priceAr !== 0) {
      setPriceArDt(
        priceAr * 1.78 + 200 + markup + (priceAr * 1.78 + 200 + markup) * 0.13
      );
    } else setPriceArDt(999999);

    if (priceTr !== 0) {
      setPriceTrDt(
        priceTr +
          priceTr * 0.1 +
          200 +
          markup +
          (priceTr + priceTr * 0.1 + 200 + markup) * 0.13
      );
    } else setPriceTrDt(999999);

    if (priceUsa !== 0) {
      setPriceUsaDt(
        priceUsa +
          priceUsa * 0.1 +
          200 +
          markup +
          (priceUsa + priceUsa * 0.1 + 200 + markup) * 0.13
      );
    } else setPriceUsaDt(999999);

    // if (priceEg !== 0) {
    //   setPriceEgDt(priceEg + priceEg * 0.16 + 200 + markup);
    // } else setPriceEgDt(999999);

    // if (priceNg !== 0) {
    //   setPriceNgDt(priceNg + priceNg * 0.16 + 200 + markup);
    // } else setPriceNgDt(999999);

    // считаем на скидках
    if (priceArSale !== 0) {
      setPriceArDtSale(
        priceArSale * 1.78 +
          200 +
          markup +
          (priceArSale * 1.78 + 200 + markup) * 0.13
      );
    } else setPriceArDtSale(999999);

    if (priceTrSale !== 0) {
      setPriceTrDtSale(
        priceTrSale +
          priceTrSale * 0.1 +
          200 +
          markup +
          (priceTrSale + priceTrSale * 0.1 + 200 + markup) * 0.13
      );
    } else setPriceTrDtSale(999999);

    if (priceUsaSale !== 0) {
      setPriceUsaDtSale(
        priceUsaSale * 1.1 +
          200 +
          markup +
          (priceUsaSale * 1.1 + 200 + markup) * 0.13
      );
    } else setPriceUsaDtSale(999999);

    // if (priceEgSale !== 0) {
    //   setPriceEgDtSale(priceEgSale + priceEgSale * 0.16 + 200 + markup);
    // } else setPriceEgDtSale(999999);

    // if (priceNgSale !== 0) {
    //   setPriceNgDtSale(priceNgSale + priceNgSale * 0.16 + 200 + markup);
    // } else setPriceNgDtSale(999999);
    // ==========
    // console.log(+priceNgDt);
    // console.log("Египет" + priceEgDt);
    // console.log("Ничегия" + priceNgSale);
  };

  useEffect(() => {
    priceCalculationConv();

    setTimeout(() => setIsSwitchCalc(true), 1000);
  }, []);

  useEffect(() => {
    priceCalculationDtDonate();
    // console.log(priceUsa);
    // console.log(searchGamePriceUsaSale);
    // console.log(priceUsaSale);
    // console.log(priceUsaDtSale);
    // console.log(priceAr);
    // console.log(priceTr);
  }, [isSwitchCalc]);

  return (
    <>
      <div className="game-info__price">
        {isSwitchCalc !== true ? (
          <div className="game-info__price-text">
            <ClipLoader color="#285f8f" />
          </div>
        ) : (
          <div>
            {" "}
            {priceArDt > 99999 && priceTrDt > 99999 ? (
              <div className="game-info__price-text">
                Товар недоступен для покупки
              </div>
            ) : (
              <div>
                <div className="game-info__price-text-sub">
                  На новый аккаунт
                </div>
                {priceArDt < priceTrDt ? (
                  <span className="game-info__price-text">
                    {Math.ceil(priceArDt / 10) * 10} рублей{" "}
                    <span style={{ fontSize: 10 }}>I</span>
                  </span>
                ) : (
                  <span className="game-info__price-text">
                    {Math.ceil(priceTrDt / 10) * 10} рублей{" "}
                    <span style={{ fontSize: 10 }}>II</span>
                  </span>
                )}

                {/* {priceArDt < priceTrDt &&
              priceArDt < priceEgDt &&
              priceArDt < priceNgDt ? (
                <span className="game-info__price-text">
                  {Math.ceil(priceArDt / 10) * 10} рублей{" "}
                  <span style={{ fontSize: 10 }}>I</span>
                </span>
              ) : (
                ""
              )}
              {priceTrDt < priceArDt &&
              priceTrDt < priceEgDt &&
              priceTrDt < priceNgDt ? (
                <span className="game-info__price-text">
                  {Math.ceil(priceTrDt / 10) * 10} рублей{" "}
                  <span style={{ fontSize: 10 }}>II</span>
                </span>
              ) : (
                ""
              )}
              {priceEgDt < priceArDt &&
              priceEgDt < priceTrDt &&
              priceEgDt < priceNgDt ? (
                <span className="game-info__price-text">
                  {Math.ceil(priceEgDt / 10) * 10} рублей{" "}
                  <span style={{ fontSize: 10 }}>III</span>
                </span>
              ) : (
                ""
              )}
              {priceNgDt < priceArDt &&
              priceNgDt < priceTrDt &&
              priceNgDt < priceEgDt ? (
                <span className="game-info__price-text">
                  {Math.ceil(priceNgDt / 10) * 10} рублей{" "}
                  <span style={{ fontSize: 10 }}>IIII</span>
                </span>
              ) : (
                ""
              )} */}
              </div>
            )}
            {(priceArDtSale > 99999 && priceTrDtSale > 99999) ||
            (priceArDtSale >= priceArDt && priceArDtSale < priceTrDtSale) ||
            (priceTrDtSale >= priceTrDt && priceTrDtSale < priceArDtSale) ? (
              ""
            ) : (
              <div>
                {priceArDtSale < priceTrDtSale ? (
                  <>
                    <div className="game-info__price-text-sub">
                      При наличии подписки
                    </div>{" "}
                    <span className="game-info__price-text">
                      {Math.ceil(priceArDtSale / 10) * 10} рублей{" "}
                      <span style={{ fontSize: 10 }}>I</span>
                    </span>
                  </>
                ) : (
                  <>
                    <div className="game-info__price-text-sub">
                      При наличии подписки
                    </div>
                    <span className="game-info__price-text">
                      {Math.ceil(priceTrDtSale / 10) * 10} рублей{" "}
                      <span style={{ fontSize: 10 }}>II</span>
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        {isSwitchCalc !== true ? (
          ""
        ) : (
          <div>
            <div className="game-info__price-text-sub">На ваш аккаунт</div>
            <span className="game-info__price-text">
              {Math.ceil(priceUsaDt / 10) * 10} рублей{" "}
            </span>

            {priceUsaDtSale > 99999 || priceUsaDtSale == priceUsaDt ? (
              ""
            ) : (
              <>
                {" "}
                <div className="game-info__price-text-sub">
                  При наличии подписки
                </div>
                <span className="game-info__price-text">
                  {Math.ceil(priceUsaDtSale / 10) * 10} рублей{" "}
                </span>
              </>
            )}
          </div>
        )}
      </div>

      {/* <div className="jotting">
        Цены не распространяются на донат и игры по обратной совместимости!
      </div> */}
      <div className="gpoup-information">
        <img className="gpoup-information__logo" src={logo} />
        <a
          className="gpoup-information__title"
          href="https://vk.com/xbogames"
          target="_blank"
          rel="noopener noreferrer"
        >
          XBOGames
        </a>
      </div>
    </>
  );
};

export default CarrencyUserMaster;

////Старый расчет

// const priceCalculationDtDonate = () => {
//   if (searchProductType === "Consumable" || whatXbox === true) {
//     const markup = 400;

//     if (priceAr !== 0) {
//       setPriceArDt(priceAr + priceAr * 0.15 + 300 + markup);
//     } else setPriceArDt(999999);

//     if (priceTr !== 0) {
//       setPriceTrDt(priceTr + priceTr * 0.15 + 300 + markup);
//     } else setPriceTrDt(999999);
//   } else {
//     priceCalculationDt();
//   }
// };

// const priceCalculationDt = () => {
//   const markup = 350;
//   // console.log("Я в новой функции");
//   // console.log(searchProductType);

//   if (priceAr !== 0) {
//     if (priceAr < 1000) {
//       setPriceArDt(priceAr + priceAr * 0.15 + 150 + markup);
//     } else if (priceAr < 1815) {
//       const sum = priceAr + priceAr * 0.15;
//       setPriceArDt(priceAr + priceAr * 0.15 + sum * 0.12 + markup);
//     } else if (priceAr > 1815) {
//       const sum = priceAr + priceAr * 0.15;
//       setPriceArDt(priceAr + priceAr * 0.15 + sum + 250 + markup);
//     }
//   } else setPriceArDt(999999);

//   if (priceTr !== 0) {
//     if (priceTr < 1000) {
//       setPriceTrDt(priceTr + priceTr * 0.15 + 150 + markup);
//     } else if (priceTr < 1815) {
//       const sum = priceTr + priceTr * 0.15;
//       setPriceTrDt(priceTr + priceTr * 0.15 + sum * 0.12 + markup);
//     } else if (priceTr > 1815) {
//       const sum = priceTr + priceTr * 0.15;
//       setPriceTrDt(priceTr + priceTr * 0.15 + sum + 250 + markup);
//     }
//   } else setPriceTrDt(999999);
// };
