import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { donate } from "../DonateArray";
import DonateItem from "../DonateItem";
import { getCarrencyCurSu } from "../../../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";

const DonateList = () => {
  const dispatch = useDispatch();
  const searchGameImg = useSelector((state) => state.search.searchGameImg);
  const searchGameName = useSelector((state) => state.search.searchGameName);
  const searchGameId = useSelector((state) => state.search.searchGameId);
  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );

  const searchGameDescription = useSelector(
    (state) => state.search.searchGameDescription
  );

  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );

  useEffect(() => {
    dispatch(getCarrencyCurSu());

    // console.log(carrencyArCurSu);
  }, []);

  return (
    <div>
      {donate.map((item) => {
        return (
          <div
            key={item.id}
            className="acor-container"
            onClick={() => {
              return <></>;
            }}
          >
            <input type="checkbox" name="chacor" id={item.nameGame} />
            <label htmlFor={item.nameGame}>{item.nameGame}</label>
            <div
              className="acor-body"
              onClick={() => {
                <DonateItem key={donate.id} {...donate} />;
              }}
            >
              {item.donate.map((donate) => {
                return <DonateItem key={donate.id} {...donate} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DonateList;
