import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  carrencyAr: "",
  carrencyTr: "",
};

export const getCarrency = createAsyncThunk(
  "CarrencySlice/getCarrency",
  //передаем ID при вызове функции на странице ,  , &page=${numberPage}
  async (id, { rejectWithValue, dispatch }) => {
    const myHeaders = new Headers();
    myHeaders.append("apikey", "ncAdfhsrvn6DoGcK9x5EndlXxXOeklde");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      "https://api.apilayer.com/fixer/latest?symbols=ars%2Ctry&base=rub",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setCarrencyAr(result.rates.ARS));
        dispatch(setCarrencyTr(result.rates.TRY));
      })
      .catch((error) => {
        dispatch(setCarrencyAr(2.24));
        dispatch(setCarrencyTr(0.31));
        console.log("error", error);
      });
  }
);

export const CarrencySlice = createSlice({
  name: "CarrencySlice",
  initialState,
  reducers: {
    //Записываем результат предлогаемого списка
    setCarrencyAr: (state, action) => {
      state.carrencyAr = action.payload;
    },

    setCarrencyTr: (state, action) => {
      state.carrencyTr = action.payload;
    },
  },
  extraReducers: {
    //   Вызывается когда запрос прошел успешно
    [getCarrency.fulfilled]: () => {
      // console.log("getCarrency fullfiled");
    },
    //   Вызывается когда вызывается запрос
    [getCarrency.pending]: () => {
      // console.log("getCarrency pending");
    },
    //   Вызывается когда запрос прошел  не успешно
    [getCarrency.rejected]: () => {
      // console.log("getCarrency rejected");
    },
  },
});

export const { setCarrencyAr, setCarrencyTr } = CarrencySlice.actions;

export default CarrencySlice.reducer;
