import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  dealsGamesListId: [], // получаем список ID игр
  newGamesListId: [],
  topPaidGamesId: [],
  isFetchingDeals: false,
  newGamesList: [],
  dealsGamesList: [],
  //   searchGameId: "", //ID игры
  //   searchGameName: "", // Название игры
  //   searchGameImg: "",
  //   searchGamePriceAr: 0, // Цена в АРГ
  //   searchGamePriceTr: 0, // Цена в ТУР
  //   searchGameDescription: "", //описание игры

  //   isFetchingGamesInfo: false,
  //   isFetchingPriceTr: false,
};
// &MS-CV=DGU1mcuYo0WMMp+0001.jsons
export const getDealsGames = createAsyncThunk(
  "DealsSlice/getDealsGames",

  async (_, { rejectWithValue, dispatch }) => {
    // const response = await axios.get(`http://localhost:3000`);
    const responseDealsGames = await axios.get(
      `https://xbogamesapi.ru/dealsGames`
    );
    const response = await axios.get(`https://xbogamesapi.ru/deals/`);

    dispatch(setDealsGamesListId(response.data));
    dispatch(setDealsGamesList(responseDealsGames.data));
  }
);

export const getNewGames = createAsyncThunk(
  "DealsSlice/getNewGames",

  async (_, { rejectWithValue, dispatch }) => {
    const responseNewGames = await axios.get(`https://xbogamesapi.ru/new`);

    const response = await axios.get(`https://xbogamesapi.ru/new/`);

    dispatch(setDealsGamesListId(response.data));
    dispatch(setNewGamesList(responseNewGames.data));
  }
);

export const getTopPaidGames = createAsyncThunk(
  "DealsSlice/getTopPaidGames",

  async (_, { rejectWithValue, dispatch }) => {
    // const response = await axios.get(`http://localhost:3000`);
    const response = await axios.get(`https://xbogamesapi.ru/toppaid/`);

    // dispatch(setDealsGamesListId(response.data));
    dispatch(setTopPaidGamesId(response.data));
  }
);

export const DealsSlice = createSlice({
  name: "DealsSlice",
  initialState,
  reducers: {
    //Записываем результат предлогаемого списка
    setDealsGamesListId: (state, action) => {
      state.dealsGamesListId = action.payload;
    },
    //Записываем результат предлогаемого списка
    setDealsGamesList: (state, action) => {
      state.dealsGamesList = action.payload;
    },
    //Записываем результат предлогаемого списка
    setNewGamesListId: (state, action) => {
      state.newGamesListId = action.payload;
    },

    //Записываем результат предлогаемого списка
    setNewGamesList: (state, action) => {
      state.newGamesList = action.payload;
    },

    //Записываем результат предлогаемого списка
    setTopPaidGamesId: (state, action) => {
      state.topPaidGamesId = action.payload;
    },
  },
  extraReducers: {
    //   Вызывается когда запрос прошел успешно
    [getDealsGames.fulfilled]: (state, action) => {
      // console.log("getDealsGames fullfiled");
      state.isFetchingDeals = true;
    },
    //   Вызывается когда вызывается запрос
    [getDealsGames.pending]: (state, action) => {
      // console.log("getDealsGames pending");
      state.isFetchingDeals = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getDealsGames.rejected]: (state, action) => {
      // console.log("getDealsGames rejected");
    },

    //   Вызывается когда запрос прошел успешно
    [getNewGames.fulfilled]: (state, action) => {
      // console.log("getNewGames fullfiled");
      state.isFetchingDeals = true;
    },
    //   Вызывается когда вызывается запрос
    [getNewGames.pending]: (state, action) => {
      // console.log("getNewGames pending");
      state.isFetchingDeals = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getNewGames.rejected]: (state, action) => {
      // console.log("getNewGames rejected");
    },

    //   Вызывается когда запрос прошел успешно
    [getTopPaidGames.fulfilled]: (state, action) => {
      // console.log("getTopPaidGames fullfiled");
      state.isFetchingDeals = true;
    },
    //   Вызывается когда вызывается запрос
    [getTopPaidGames.pending]: (state, action) => {
      // console.log("getTopPaidGames pending");
      state.isFetchingDeals = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getTopPaidGames.rejected]: (state, action) => {
      // console.log("getTopPaidGames rejected");
    },
  },
});

export const {
  setDealsGamesListId,
  setDealsGamesList,
  setNewGamesListId,
  setNewGamesList,
  setTopPaidGamesId,
} = DealsSlice.actions;

export default DealsSlice.reducer;
