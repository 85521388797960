import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  carrencyRuCurSu: "",
  carrencyArCurSu: "",
  carrencyTrCurSu: "",
  // carrencyEgCurSu: "",
  // carrencyNgCurSu: "",
  // carrencyKrwCurSu: "",
  // carrencyNzdCurSu: "",
  // carrencyZarCurSu: "",
  // carrencyHufCurSu: "",
  // carrencyJpyCurSu: "",
  // carrencyCopCurSu: "",
  isFetchingCarrency: false,
};

export const getCarrencyCurSu = createAsyncThunk(
  "CarrencySlice/getCarrency",
  //передаем ID при вызове функции на странице ,  , &page=${numberPage}
  async (id, { rejectWithValue, dispatch }) => {
    // const response = await axios.get(`https://cdn.cur.su/api/latest.json`);

    // dispatch(setCarrencyRuCurSu(response.data.rates.RUB));
    // dispatch(setCarrencyArCurSu(response.data.rates.ARS));
    // dispatch(setCarrencyTrCurSu(response.data.rates.TRY));

    // dispatch(setCarrencyRuCurSu(62.8));
    // dispatch(setCarrencyArCurSu(162.46));
    // dispatch(setCarrencyTrCurSu(18.62));

    const response = await axios.get(`https://xbogamesapi.ru/curs`);

    // dispatch(setCarrencyRuCurSu(76.61));
    dispatch(setCarrencyRuCurSu(response.data.rub));
    dispatch(setCarrencyArCurSu(response.data.ars));
    dispatch(setCarrencyTrCurSu(response.data.try));
    // dispatch(setCarrencyEgCurSu(response.data.egp));
    // dispatch(setCarrencyNgCurSu(response.data.ngn));
    //   dispatch(setCarrencyKrwCurSu(response.data.krw));
    //   dispatch(setCarrencyNzdCurSu(response.data.nzd));
    //   dispatch(setCarrencyZarCurSu(response.data.zar));

    //   dispatch(setCarrencyHufCurSu(response.data.huf));
    //   dispatch(setCarrencyJpyCurSu(response.data.jpy));
    //   dispatch(setCarrencyCopCurSu(response.data.cop));
  }
);

export const CarrencyCurSuSlice = createSlice({
  name: "CarrencyCurSuSlice",
  initialState,
  reducers: {
    setCarrencyRuCurSu: (state, action) => {
      state.carrencyRuCurSu = action.payload;
    },

    setCarrencyArCurSu: (state, action) => {
      state.carrencyArCurSu = action.payload;
    },

    setCarrencyTrCurSu: (state, action) => {
      state.carrencyTrCurSu = action.payload;
    },

    // setCarrencyEgCurSu: (state, action) => {
    //   state.carrencyEgCurSu = action.payload;
    // },
    // setCarrencyNgCurSu: (state, action) => {
    //   state.carrencyNgCurSu = action.payload;
    // },

    // setCarrencyKrwCurSu: (state, action) => {
    //   state.carrencyKrwCurSu = action.payload;
    // },

    // setCarrencyNzdCurSu: (state, action) => {
    //   state.carrencyNzdCurSu = action.payload;
    // },

    // setCarrencyZarCurSu: (state, action) => {
    //   state.carrencyZarCurSu = action.payload;
    // },

    // setCarrencyHufCurSu: (state, action) => {
    //   state.carrencyHufCurSu = action.payload;
    // },

    // setCarrencyJpyCurSu: (state, action) => {
    //   state.carrencyJpyCurSu = action.payload;
    // },

    // setCarrencyCopCurSu: (state, action) => {
    //   state.carrencyCopCurSu = action.payload;
    // },
  },
  extraReducers: {
    //   Вызывается когда запрос прошел успешно
    [getCarrencyCurSu.fulfilled]: (state) => {
      state.isFetchingCarrency = false;
      // console.log("getCarrencyCurSu fullfiled");
      // console.log(state.carrencyRuCurSu);
    },
    //   Вызывается когда вызывается запрос
    [getCarrencyCurSu.pending]: (state) => {
      state.isFetchingCarrency = true;
      // console.log("getCarrencyCurSu pending");
    },
    //   Вызывается когда запрос прошел  не успешно
    [getCarrencyCurSu.rejected]: (state) => {
      // console.log("getCarrencyCurSu rejected");
      state.carrencyRuCurSu = 89.61;
      state.carrencyArCurSu = 826.53;
      state.carrencyTrCurSu = 30.9;
      // state.carrencyEgCurSu = 30.9;
      // state.carrencyNgCurSu = 951;
    },
  },
});

export const {
  setCarrencyArCurSu,
  setCarrencyTrCurSu,
  setCarrencyRuCurSu,
  // setCarrencyKrwCurSu,
  // setCarrencyNzdCurSu,
  // setCarrencyZarCurSu,
  // setCarrencyHufCurSu,
  // setCarrencyJpyCurSu,
  // setCarrencyCopCurSu,

  // setCarrencyEgCurSu,
  // setCarrencyNgCurSu,
} = CarrencyCurSuSlice.actions;

export default CarrencyCurSuSlice.reducer;
