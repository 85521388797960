import { configureStore } from "@reduxjs/toolkit";
import SearchSlice from "../features/SearchSlice/SearchSlice.js";
import CarrencySlice from "../features/CarrencySlice/CarrencySlice.js";
import CarrencyCurSuSlice from "../features/CarrencyCurSuSlice/CarrencyCurSuSlice.js";
import DealsSlice from "../features/DealsSlice/DealsSlice.js";

export const store = configureStore({
  reducer: {
    search: SearchSlice,
    carrency: CarrencySlice,
    carrencyCurSu: CarrencyCurSuSlice,
    deals: DealsSlice,
  },
});
