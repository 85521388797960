import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClockLoader } from "react-spinners";
import gridImg from "../../../img/control/listing-view-grid.svg";
import rowImg from "../../../img/control/listing-view-row.svg";
import { getTopPaidGames } from "../../../features/DealsSlice/DealsSlice";
import DealsHomeGames from "../DealsHomeGames";
import DealsItemGames from "../DealsItemGames";

const PageTopPaid = () => {
  const dispatch = useDispatch();
  const isFetchingDeals = useSelector((state) => state.deals.isFetchingDeals);
  const topPaidGamesList = useSelector((state) => state.deals.topPaidGamesId);

  const [sorting, setSorting] = useState("all");
  let sort = [];

  const [sortingArr, setSortingArr] = useState([]);

  const [list, setList] = useState(false);

  const [rowGrid, setRowGrid] = useState(true);

  useEffect(() => {
    // console.log(dealsGamesList);
    dispatch(getTopPaidGames());
  }, []);

  useEffect(() => {
    // console.log(dealsGamesListId);
    // sort = [...dealsGamesListId].sort(byField("name"))
    if (sorting === "alphabet") {
      setSortingArr([...topPaidGamesList].sort(byField("name")));
      // console.log(sortingArr);
    }
    if (sorting === "price") {
      setSortingArr([...topPaidGamesList].sort(byField("price")));
      // console.log(sortingArr);
    }
  }, [sorting]);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  const GridRow = () => {
    setList((prev) => !prev);
    setRowGrid((prev) => !prev);
  };

  return (
    <>
      <div className="gpoup-information__text" style={{ margin: "20px" }}>
        ТОП продаж
        {/* <div> {`Всего ${dealsGamesListId.length} игр и dlc`}</div> */}
      </div>

      {isFetchingDeals && (
        <div className="control">
          <div className="sorting">
            <select
              className="select-css"
              onChange={(e) => {
                setSorting(e.target.value);
              }}
            >
              <option value="all">По умолчанию</option>
              <option value="alphabet">По алфавиту</option>
              <option value="price">По цене</option>
            </select>
          </div>

          <div className="control-list">
            <img
              onClick={() => GridRow()}
              src={rowImg}
              alt="row"
              className={`control__img ${
                rowGrid ? "control__img--disabled" : " "
              }`}
            />
            <img
              onClick={() => GridRow()}
              src={gridImg}
              alt="grid"
              className={`control__img ${
                rowGrid ? "" : "control__img--disabled "
              }`}
            />
          </div>
        </div>
      )}

      {sorting === "all" && (
        <>
          {isFetchingDeals === true ? (
            <div className={`deals ${list ? "deals__list" : " "}`}>
              {topPaidGamesList.map((item) => (
                <DealsItemGames key={item.gameId} item={item} list={list} />
              ))}
            </div>
          ) : (
            <div className="deals deals-spinner">
              {" "}
              <ClockLoader color="#36d67b" />{" "}
            </div>
          )}
        </>
      )}

      {sorting === "alphabet" && (
        <>
          {isFetchingDeals === true ? (
            <div className={`deals ${list ? "deals__list" : " "}`}>
              {sortingArr.map((item) => (
                <DealsItemGames key={item.gameId} item={item} list={list} />
              ))}
            </div>
          ) : (
            <div className="deals deals-spinner">
              {" "}
              <ClockLoader color="#36d67b" />{" "}
            </div>
          )}
        </>
      )}

      {sorting === "price" && (
        <>
          {isFetchingDeals === true ? (
            <div className={`deals ${list ? "deals__list" : " "}`}>
              {sortingArr.map((item) => (
                <DealsItemGames key={item.gameId} item={item} list={list} />
              ))}
            </div>
          ) : (
            <div className="deals deals-spinner">
              {" "}
              <ClockLoader color="#36d67b" />{" "}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PageTopPaid;
