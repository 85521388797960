import "./styles.css";

import { useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

import HomePageInstall from "../HomePageInstall";
import HomePageSearch from "../HomePageSearch";
import HomePageDeals from "../HomePageDeals";
import HomePageDonate from "../HomePageDonate";
import logo from "../../../img/logo.png";
import logoXbox from "../../../img/home/logo_home.png";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <div className="container1">
      <div className="wrapper">
        {/* <img className="gpoup-information__logo" src={logoXbox} /> */}
        <div className="gpoup-information__text" style={{ margin: "0px" }}>
          Интернет магазин
          {/* <div> {`Всего ${dealsGamesListId.length} игр и dlc`}</div> */}
          <div className="gpoup-information-deals">
            <img className="gpoup-information-deals__logo" src={logo} />
            <a
              className="gpoup-information__title"
              href="https://vk.com/xbogames"
              target="_blank"
              rel="noopener noreferrer"
            >
              XBOGames
            </a>
          </div>
        </div>

        <div
          onClick={() => {
            navigate(`/xcalc`);
          }}
        >
          <HomePageSearch />
        </div>
        <div
          onClick={() => {
            navigate(`/deals`);
          }}
        >
          <HomePageDeals />
        </div>
        <div
          onClick={() => {
            navigate(`/donate`);
          }}
        >
          <HomePageDonate />
        </div>
        {/* <div>
          <HomePageInstall />
        </div> */}
      </div>
    </div>
  );
};

export default HomePage;
