import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarrency } from "../../features/CarrencySlice/CarrencySlice";
import {
  getSearchGamesInfo,
  getSearchGamesList,
  getSearchGamesInfoPriceTr,
  setSearchGamesList,
  setPriceArTest,
  setPriceTrTest,
} from "../../features/SearchSlice/SearchSlice.js";

const Carrency = () => {
  const dispatch = useDispatch();
  // const carrencyAr = useSelector((state) => state.carrency.carrencyAr); //курс рубля к ARS
  // const carrencyTr = useSelector((state) => state.carrency.carrencyTr); //курс рубля к TRY

  const carrencyAr = 2.207; // Курс арг в рублю
  const carrencyTr = 0.301; // Курс тур в рублю

  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );

  const carrencyTrCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyTrCurSu
  );

  const carrencyRuCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyRuCurSu
  );

  const MARKUP = 350; //наша наценка

  const isFetchingGamesInfo = useSelector(
    (state) => state.search.isFetchingGamesInfo
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );

  const searchGameName = useSelector((state) => state.search.searchGameName);
  const searchGameImg = useSelector((state) => state.search.searchGameImg);

  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );

  const [priceAr, setPriceAr] = useState(0); // цена в рублях в аргентине
  const [priceTr, setPriceTr] = useState(0); // цена в рублях в турции

  const [priceArDt, setPriceArDt] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam
  const [priceTrDt, setPriceTrDt] = useState(0); // цена в рублях в турции c накруткой DreamTeam

  const [isSwitchCalc, setIsSwitchCalc] = useState(false);

  const priceCalculationConv = () => {
    console.log("чык-чырык");
    // const ruAr = carrencyArCurSu / carrencyRuCurSu;
    // const DROPAR = ruAr + ruAr * 0.03;
    // console.log(DROPAR + " drop");
    // const ruTr = carrencyTrCurSu / carrencyRuCurSu;
    // const DROPTR = ruTr + ruTr * 0.03;
    // console.log(ruAr + "RU AR");
    // setPriceAr(searchGamePriceAr / DROPAR); // цена в рублях в аргентине

    // setPriceTr(searchGamePriceTr / DROPTR); // цена в рублях в турции

    setPriceAr(searchGamePriceAr / (carrencyArCurSu / carrencyRuCurSu)); // цена в рублях в аргентине
    setPriceTr(searchGamePriceTr / (carrencyTrCurSu / carrencyRuCurSu)); // цена в рублях в турции
  };

  const priceCalculationDt = () => {
    // console.log("Я в новой функции");
    // console.log("Цена в арг " + priceAr);
    if (priceAr != 0) {
      if (priceAr < 1090) {
        setPriceArDt(priceAr + priceAr * 0.15 + 150);
      } else {
        const sum = priceAr + priceAr * 0.15;
        setPriceArDt(priceAr + priceAr * 0.15 + sum * 0.12);
      }
    } else setPriceArDt(999999);

    if (priceTr != 0) {
      if (priceTr < 1090) {
        setPriceTrDt(priceTr + priceTr * 0.15 + 150);
      } else {
        const sum = priceTr + priceTr * 0.15;
        setPriceTrDt(priceTr + priceTr * 0.15 + sum * 0.12);
      }
    } else setPriceTrDt(999999);
  };

  useEffect(() => {
    priceCalculationConv();
    // priceCalculationDt();
    setTimeout(() => setIsSwitchCalc(true), 1000);
  }, []);

  useEffect(() => {
    priceCalculationDt();
  }, [isSwitchCalc]);

  return (
    <div>
      <div style={{ fontSize: 30 }}>Курсы валют</div>
      <div>курс рубля к ARS {carrencyArCurSu / carrencyRuCurSu} </div>
      <div>курс рубля к TRY {carrencyTrCurSu / carrencyRuCurSu} </div>
      <div style={{ fontSize: 30 }}>Цена в регионах</div>
      <div>Цена Арг {searchGamePriceAr}</div>
      <div>Цена Тур {searchGamePriceTr}</div>
      <div style={{ fontSize: 30 }}>Цена в регионах в рублях</div>
      {/* <div>
        верстка цена в рублях в аргентине {searchGamePriceAr / carrencyAr}
      </div>
      <div>верстка цена в рублях в турции {searchGamePriceTr / carrencyTr}</div> */}
      <div>цена в рублях в аргентине {priceAr}</div>
      <div>цена в рублях в турции {priceTr}</div>
      <div style={{ fontSize: 30 }}>
        Цена в регионах в рублях с накруткой DreamTeam
      </div>
      <div>{priceArDt}</div>
      <div>{priceTrDt}</div>
      <div style={{ fontSize: 30 }}>
        Цена в регионах в рублях с нашей накруткой
      </div>
      <div>{priceArDt + MARKUP}</div>
      <div>{priceTrDt + MARKUP}</div>
      <div style={{ fontSize: 30 }}>Лучшая цена</div>
      {priceArDt < priceTrDt ? (
        <div>В аргентине {priceArDt + MARKUP}</div>
      ) : (
        <div>В турции {priceTrDt + MARKUP}</div>
      )}
    </div>
  );
};

export default Carrency;
