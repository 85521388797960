import "./styles.css";

import { useEffect } from "react";
import React from "react";

const HomePageDonate = () => {
  useEffect(() => {}, []);

  return (
    <div className="donate-home">
      <div className="donate-home__wrapper">
        <h2 className="donate-home__heading">Список игровой валюты </h2>

        <p className="deals-home__paragraph">Это всего лишь начало</p>
        <span className="deals-home__button">
          Посмотреть список игровой валюты
        </span>
      </div>
    </div>
  );
};

export default HomePageDonate;
