import "./styles.css";
import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchGamesInfo,
  getSearchGamesList,
  getSearchGamesInfoPriceTr,
  setSearchGamesList,
} from "../../features/SearchSlice/SearchSlice.js";

// import { getSearchGamesInfoPriceTr } from "../../features/SearchPriceTrSlice/SearchPriceTrSlice";
import GamePage from "../gamePage";
import Carrency from "../Carrency";

import { getCarrency } from "../../features/CarrencySlice/CarrencySlice";
import { getCarrencyCurSu } from "../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";

const SearchGame = () => {
  const dispatch = useDispatch();
  const searchGamesList = useSelector((state) => state.search.searchGamesList);

  const isFetchingGamesInfo = useSelector(
    (state) => state.search.isFetchingGamesInfo
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );

  useEffect(() => {
    // dispatch(getCarrency());
    dispatch(getCarrencyCurSu());
  }, []);

  return (
    <div>
      <div className="search-master">
        <div>Поиск цены для игр Xbox</div>
        <input
          className="search-input-master"
          type="text"
          onChange={(e) => dispatch(getSearchGamesList(e.target.value))}
          onBlur={(e) => {
            e.target.value = "";
            setSearchGamesList([]);
            // setTimeout(() => ((e.target.value = ""), 500));
            // setTimeout(() => dispatch(setSearchGamesList([])), 500);
          }}
        />

        <div className="search-list-master">
          {searchGamesList.map((game) => (
            <div
              key={game.Metas[0].Value}
              className="search-list__item-master"
              onClick={() => {
                dispatch(getSearchGamesInfo(game.Metas[0].Value));
                dispatch(getSearchGamesInfoPriceTr(game.Metas[0].Value));
                dispatch(setSearchGamesList([]));
                // setTimeout(() => dispatch(setSearchGamesList([])), 1000);
              }}
            >
              <img style={{ width: 40 }} src={game.Metas[2].Value} alt="" />
              {game.Title}
            </div>
          ))}
        </div>
      </div>
      <div className="game-info-master">
        {isFetchingPriceTr && isFetchingGamesInfo ? (
          <GamePage />
        ) : (
          <div>Загрузка...</div>
        )}

        {isFetchingPriceTr && isFetchingGamesInfo ? <Carrency /> : null}
      </div>
    </div>
  );
};

export default SearchGame;
