import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ClipLoader, GridLoader } from "react-spinners";

const DealsItemList = ({ id }) => {
  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );
  const carrencyTrCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyTrCurSu
  );
  const carrencyRuCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyRuCurSu
  );

  // const [priceAr, setPriceAr] = useState(0); // цена в рублях в аргентине
  // const [priceTr, setPriceTr] = useState(0); // цена в рублях в турции

  const [nameDonate, setNameDonate] = useState(0);
  const [donateImg, setDonateImg] = useState(0);
  // const [donatePriceAr, setDonatePriceAr] = useState(0); // Цена в АРС
  // const [donatePriceTr, setDonatePriceTr] = useState(0); // Цена в Лирах
  const MARKUP = 350;
  //   console.log(id);

  const getDonateInfo = async () => {
    const responseAr = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=ar&languages=ru-ru`
    );

    const responseTr = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=tr&languages=ru-ru`
    );
    // let image = {};
    let image = responseAr.data.Products[0].LocalizedProperties[0].Images.find(
      (item) => item.ImagePurpose === "Poster"
    );

    setNameDonate(
      responseAr.data.Products[0].DisplaySkuAvailabilities[0].Sku
        .LocalizedProperties[0].SkuTitle
    );

    if (!!image) {
      setDonateImg(
        // responseAr.data.Products[0].LocalizedProperties[0].Images[i].Uri
        image.Uri
      );
    } else
      setDonateImg(
        "//img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE2TAjc"
      );

    // console.log(image.Uri);
    const whatXbox =
      responseAr.data.Products[0].Properties.PackageFamilyName?.includes(
        "Xbox360"
      );

    let donateInfo = {};
    if (!!image) {
      donateInfo = {
        // nameDoname:
        //   responseAr.data.Products[0].DisplaySkuAvailabilities[0].Sku
        //     .LocalizedProperties[0].SkuTitle,
        // donateImg:
        //   image.Uri,

        donatePriceAr:
          responseAr.data.Products[0].DisplaySkuAvailabilities[0]
            .Availabilities[0].OrderManagementData.Price.ListPrice,
        donatePriceTr:
          responseTr.data.Products[0].DisplaySkuAvailabilities[0]
            .Availabilities[0].OrderManagementData.Price.ListPrice,
        xbox360: whatXbox,
        searchProductType: responseAr.data.Products[0].ProductType,
      };
    } else {
      donateInfo = {
        // nameDoname: "1",
        // donateImg:
        //   "//store-images.s-microsoft.com/image/apps.29047.68229652143243138.0f29fa50-a819-41fa-aa7a-be9caea6782e.c7e76764-c54e-4eee-845c-0fa0259c42a7",
        donatePriceAr: 1,
        donatePriceTr: 1,
        xbox360: 1,
        searchProductType: 1,
      };
    }

    return donateInfo;
  };

  const [isSwitchCalcDt, setIsSwitchCalcDt] = useState(false);

  const [priceArDt, setPriceArDt] = useState(); // цена в рублях в аргентине c накруткой DreamTeam
  const [priceTrDt, setPriceTrDt] = useState(); // цена в рублях в турции c накруткой DreamTeam

  const priceCalculationDt = () => {
    const price = getDonateInfo()
      .then((res) => {
        const ar = res.donatePriceAr / (carrencyArCurSu / carrencyRuCurSu);
        const tr = res.donatePriceTr / (carrencyTrCurSu / carrencyRuCurSu);

        if (res.searchProductType === "Consumable") {
          // if (res.searchProductType === "Consumable" || res.xbox360 === true) {
          const markup = 400;

          if (ar !== 0) {
            setPriceArDt(ar * 1.86 + 200 + markup);
            // if (ar < 1670) {
            //   setPriceArDt(ar + ar * 0.15 + 200 + markup);
            // } else if (ar < 2920) {
            //   const sum = ar + ar * 0.15;
            //   setPriceArDt(ar + ar * 0.15 + sum * 0.12 + markup);
            // } else if (ar > 2920) {
            //   const sum = ar + ar * 0.15;
            //   setPriceArDt(ar + ar * 0.15 + 350 + markup);
            // }
          } else setPriceArDt(999999);

          if (tr !== 0) {
            setPriceTrDt(tr + tr * 0.16 + 200 + markup);
            // if (tr < 1670) {
            //   setPriceTrDt(tr + tr * 0.15 + 200 + markup);
            // } else if (tr < 2920) {
            //   const sum = tr + tr * 0.15;
            //   setPriceTrDt(tr + tr * 0.15 + sum * 0.12 + markup);
            // } else if (tr > 2920) {
            //   const sum = tr + tr * 0.15;
            //   setPriceTrDt(tr + tr * 0.15 + 350 + markup);
            // }
          } else setPriceTrDt(999999);
        } else {
          const markup = 300;
          // console.log("Я в новой функции");
          // console.log(searchProductType);

          if (ar !== 0) {
            setPriceArDt(ar * 1.86 + 200 + markup);
            // if (ar < 1670) {
            //   setPriceArDt(ar + ar * 0.15 + 200 + markup);
            // } else if (ar < 2920) {
            //   const sum = ar + ar * 0.15;
            //   setPriceArDt(ar + ar * 0.15 + sum * 0.12 + markup);
            // } else if (ar > 2920) {
            //   const sum = ar + ar * 0.15;
            //   setPriceArDt(ar + ar * 0.15 + 350 + markup);
            // }
          } else setPriceArDt(999999);

          if (tr !== 0) {
            setPriceTrDt(tr + tr * 0.16 + 200 + markup);
            // if (tr < 1670) {
            //   setPriceTrDt(tr + tr * 0.15 + 200 + markup);
            // } else if (tr < 2920) {
            //   const sum = tr + tr * 0.15;
            //   setPriceTrDt(tr + tr * 0.15 + sum * 0.12 + markup);
            // } else if (tr > 2920) {
            //   const sum = tr + tr * 0.15;
            //   setPriceTrDt(tr + tr * 0.15 + 350 + markup);
            // }
          } else setPriceTrDt(999999);
        }
      })
      .then(() => {
        setIsSwitchCalcDt(true);
      });
  };

  useEffect(() => {
    priceCalculationDt();
  }, [isSwitchCalcDt]);

  return (
    <>
      {isSwitchCalcDt !== true ? (
        <div className="loader">
          <GridLoader color="#36d66c" />
        </div>
      ) : (
        <div className="product-item-list">
          <div className="product-info">
            {" "}
            <div>
              {nameDonate}

              {priceArDt === 0 || isNaN(priceArDt) ? (
                <ClipLoader color="#36d66c" />
              ) : (
                <>
                  {" "}
                  {priceArDt > 99999 && priceTrDt > 99999 ? (
                    <span>Товар недоступен для покупки</span>
                  ) : (
                    <>
                      {" "}
                      {priceArDt < priceTrDt ? (
                        <span>- {Math.ceil(priceArDt / 10) * 10} рублей </span>
                      ) : (
                        <span>- {Math.ceil(priceTrDt / 10) * 10} рублей </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DealsItemList;

// Старый расчет

// const priceCalculationDt = () => {
//   const price = getDonateInfo()
//     .then((res) => {
//       const ar = res.donatePriceAr / (carrencyArCurSu / carrencyRuCurSu);
//       const tr = res.donatePriceTr / (carrencyTrCurSu / carrencyRuCurSu);

//       if (res.searchProductType === "Consumable" || res.xbox360 === true) {
//         const markup = 400;

//         if (ar !== 0) {
//           setPriceArDt(ar + ar * 0.15 + 300 + markup);
//         } else setPriceArDt(999999);

//         if (tr !== 0) {
//           setPriceTrDt(tr + tr * 0.15 + 300 + markup);
//         } else setPriceTrDt(999999);
//       } else {
//         const markup = 350;
//         if (ar !== 0 && res.xbox360 === false) {
//           if (ar < 1000) {
//             setPriceArDt(ar + ar * 0.15 + 150 + markup);
//           } else if (ar < 1815) {
//             const sum = ar + ar * 0.15;
//             setPriceArDt(ar + ar * 0.15 + sum * 0.12 + markup);
//           } else if (ar > 1815) {
//             const sum = ar + ar * 0.15;
//             setPriceArDt(ar + ar * 0.15 + sum + 250 + markup);
//           }
//         } else setPriceArDt(999999);

//         if (tr !== 0 && res.xbox360 === false) {
//           if (tr < 1000) {
//             setPriceTrDt(tr + tr * 0.15 + 150 + markup);
//           } else if (tr < 1815) {
//             const sum = tr + tr * 0.15;
//             setPriceTrDt(tr + tr * 0.15 + sum * 0.12 + markup);
//           } else if (tr > 1815) {
//             const sum = tr + tr * 0.15;
//             setPriceTrDt(tr + tr * 0.15 + sum + 250 + markup);
//           }
//         } else setPriceTrDt(999999);
//       }
//     })
//     .then(() => {
//       setIsSwitchCalcDt(true);
//     });
// };
