import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
// import App from "./App";
import bridge from "@vkontakte/vk-bridge";

import "./index.css";
import Router from "./components/navigation";

const container = document.getElementById("root");
const root = createRoot(container);
bridge.send("VKWebAppInit", {});

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <App /> */}
    <Router />
  </Provider>
  // </React.StrictMode>
);
